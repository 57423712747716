import { CategorySchema, AttributeSchema, FeatureSchema, ProductSchema, VariationSchema, FeatureValueSchema } from '@/schema/productDataSchemas';
import { AttributeSelection, FeatureSelection } from '@/schema/orderDataSchema';
import axios from 'axios';
import { defineStore } from 'pinia';
import { deepCopy } from 'deep-copy-ts';

export type SourceDataStore = {
  categories: Array<CategorySchema> | null;
  attributes: Array<AttributeSchema> | null;
  features: Array<FeatureSchema> | null;
  products: Array<ProductSchema> | null;
  isLoaded: boolean;
  isError: boolean;
}

/**
 * Fetches source data 
 * @param dataType data type to fetch
 * @returns source data if fetched successfully
 */
export async function fetchSourceData(dataType: string): Promise<any> {
  const path = `/data/${dataType}.json`;
  try {
    const response = await axios.get(path);
    return response.data;
  }
  catch (error) {
    console.error(`Error loading JSON data from file ${path}`, error)
    throw error;
  }
}

/**
 * Stores available product attribute values
 */
export type AvailableAttributeValues = {
  [key: string]: Set<string>;
}

/**
 * Filters product's variation by specified attributes values
 * @param product product, which variations must be filtered
 * @param attributeValues attribute values selections
 * @returns Array of variations that match filter
 */
export function filterVariations(product: ProductSchema, attributeValues: AttributeSelection): VariationSchema[] {
  if (Object.keys(attributeValues).length === 0) {
    return product.variations;
  }
  return product.variations
    .filter((variation) => {
      for (const attribute in attributeValues) {
        if (variation.attributes[attribute] !== attributeValues[attribute]) {
          return false;
        }
      }
      return true;
    });
}
/**
 * Filters product's variations and generates available attributes
 * @param product product, which available attribute values must be generated
 * @param attributeValues attribute values selections
 * @returns Available 
 */
export function getAvailableAttributeValues(product: ProductSchema, attributeValues: AttributeSelection): AvailableAttributeValues {
  const variations: VariationSchema[] = filterVariations(product, attributeValues);
  const result: AvailableAttributeValues = {};
  for (const variation of variations) {
    for (const attribute in variation.attributes ) {
      if (!(attribute in result)) {
        result[attribute] = new Set<string>();
      }
      result[attribute].add(variation.attributes[attribute]); // add attribute value to result
    }
  }
  return result;
}

export const useSourceDataStore = defineStore('SourceDataStore', {
  state: () => ({
    categories: null,
    attributes: null,
    features: null,
    products: null,
    isLoaded: false,
    isError: false
  } as SourceDataStore),
  actions: {
    /**
     * Fetches product data from server
     */
    async loadData() {
      this.isLoaded = false;
      
      try{
        this.categories = await fetchSourceData('categories') as Array<CategorySchema>;
        this.attributes = await fetchSourceData('attributes') as Array<AttributeSchema>;
        this.features = await fetchSourceData('features') as Array<FeatureSchema>;
        this.products = await fetchSourceData('products') as Array<ProductSchema>;
      } catch {
        this.isError = true;
      }

      this.isLoaded = true;
    },
    /**
     * Finds product by it's slug
     * @param productSlug product's slug
     * @returns product or undefined
     */
    getProduct(productSlug: string): ProductSchema | undefined {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.products!.find((product) => product.slug === productSlug);
    },
    /**
     * Generates list of attributes with values applicable to this product
     * @param product product, which attributes must be extracted
     * @returns array of attributes
     */
    getProductAttributes(product: ProductSchema): AttributeSchema[] {
      const availableAttributes: Map<string, Set<string>> = new Map<string, Set<string>>();
      const result: Array<AttributeSchema> = [];
      product.variations.forEach((variation) => {
        for (const attribute in variation.attributes) {
          if (!availableAttributes.has(attribute)) {
            availableAttributes.set(attribute, new Set<string>());
          }
          availableAttributes.get(attribute).add(variation.attributes[attribute]);
        }
      });
    
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.attributes!.forEach((attribute) => {
        if (!availableAttributes.has(attribute.slug)) {
          return;
        }
        const resultAttribute = Object.assign({}, attribute);
        resultAttribute.values = resultAttribute.values.filter((attributeValue) =>
          availableAttributes.get(attribute.slug).has(attributeValue.slug));
        result.push(resultAttribute);
      });
      return result;
    },
    /**
     * Generates list of features with values applicable to this product
     * @param product product, which features must be extracted
     * @returns list of features
     */
    getProductFeatures(product: ProductSchema): FeatureSchema[] {
      const result: FeatureSchema[] = [];
      const productFeatureKeys = Object.keys(product.features);

      for (const feature of this.features) {
        if (!productFeatureKeys.includes(feature.slug)) continue;
        const newFeature = deepCopy(feature) as FeatureSchema;
        newFeature.values = newFeature.values.filter((featureValue) => {
          return product.features[feature.slug].includes(featureValue.slug); 
        });
        result.push(newFeature);
      }
      return result;
    },
    /**
     * Calculates price of feature values based on feature selection
     * @param featureSelection Selected features object
     * @returns calculated price for features
     */
    calculateFeaturesPrice(featureSelection: FeatureSelection): number {
      let result = 0.00;
      Object.keys(featureSelection).forEach(selectedFeatureSlug => {
        const selectedFeatureValueSlugs = featureSelection[selectedFeatureSlug];
        const feature = this.features.find(featureSource => featureSource.slug === selectedFeatureSlug);
        feature.values.forEach((featureValue) => {
          if (!selectedFeatureValueSlugs.includes(featureValue.slug)) return;
          result += +featureValue.price;
        });
      });
      return result;
    }
  }
});