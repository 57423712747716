<template>
  <div class="category-select-view">
    <!-- <ul class="categories">
      <li v-for="category in categories" :key="category.slug">
        <p v-on:click="categorySelected(category.slug)">{{ category.title }}</p>
        <ul v-if="category.children">
          <li v-for="subcategory in category.children" :key="subcategory.slug">
            <p v-on:click="categorySelected(subcategory.slug)">{{ subcategory.title }}</p>
          </li>
        </ul>
      </li>
    </ul> -->
    <div class="category-section section" v-for="category in categories" :key="category.slug">
      <button class="category-button category-main" @click="categorySelected(category.slug)">{{ category.title }}</button>
      <div class="subcategory-section">
        <button v-for="subcategory in category.children" :key="subcategory.slug" class="category-button category-sub" @click="categorySelected(subcategory.slug)">
          {{ subcategory.title }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.category-button {
  border: 0.1rem solid var(--app-theme-button-color);
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  transition: 0.3s;
  background-color: transparent;
}

.category-main {
  background-color: var(--app-theme-button-color) !important;
  color: var(--app-theme-button-text-color);
  font-size: 1.5rem;
}

.category-main:hover {
  background-color: transparent !important;
  color: var(--app-theme-link-color);
}

.category-sub {
  margin-top: 0.3rem;
  margin-right: 0.3rem;
}

.category-sub:hover {
  background-color: var(--app-theme-button-color);
  color: var(--app-theme-button-text-color);
}

.category-section {
  display: flex;
  flex-direction: column;
}

.subcategory-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { useSourceDataStore } from '@/store/SourceDataStore';
import router from '@/router';
import { useWebAppMainButton } from 'vue-tg';
import { useOrderStore } from '@/store/OrderStore';

const { showMainButton, enableMainButton, setMainButtonText, onMainButtonClicked } = useWebAppMainButton();

const sourceDataStore = useSourceDataStore();
const orderStore = useOrderStore();
const categories = sourceDataStore.categories;


onMounted(() => {
  enableMainButton();
  setMainButtonText(`ОТКРЫТЬ КОРЗИНУ (${orderStore.orderItems.length})`);
  showMainButton();
});

onMainButtonClicked(() => {
  router.push({
    name: "order-view"
  });
});

const categorySelected = (slug: string) => {
  router.push({
    name: "product-select",
    params: { categorySlug: slug },
  });
};
</script>