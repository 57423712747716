import { createRouter, createWebHistory } from "vue-router";
import CategorySelect from "./page/CategorySelect.vue";

const routes = [
  {
    name: "category-select",
    path: "/",
    component: CategorySelect,
  },
  {
    name: "product-select",
    path: "/category/:categorySlug",
    component: () => import("@/page/ProductSelect.vue"),
  },
  {
    name: "product-view",
    path: "/products/:productSlug",
    component: () => import("@/page/ProductView.vue")
  },
  {
    name: "order-view",
    path: "/order",
    component: () => import("@/page/OrderView.vue")
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
});

export default router;