<template>
  <div class="main">
    <router-view v-if="!isLoading" />
    <ProductDataLoadingView v-else />
  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import "https://www.nerdfonts.com/assets/css/webfont.css";

.fab-wrapper {
  display: block;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999999999;
}

.fab-inner {
  position: relative;
}

.fab {
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 50% 50%;
  border: none;
  background-color: var(--app-theme-button-color);
  color: var(--app-theme-button-text-color);
  font-size: 1.25rem;

}

:root {
  /* variables */
  --app-color-scheme: var(--tg-color-scheme, light);
  --app-theme-accent-text-color: var(--tg-theme-accent-text-color, #168acd);
  --app-theme-bg-color: var(--tg-theme-bg-color, #ffffff);
  --app-theme-button-color: var(--tg-theme-button-color, #40a7e3);
  --app-theme-button-text-color: var(--tg-theme-button-text-color, #ffffff);
  --app-theme-destructive-text-color: var(--tg-theme-destructive-text-color, #d14e4e);
  --app-theme-header-bg-color: var(--tg-theme-header-bg-color, #ffffff);
  --app-theme-hint-color: var(--tg-theme-hint-color, #999999);
  --app-theme-link-color: var(--tg-theme-link-color, #168acd);
  --app-theme-secondary-bg-color: var(--tg-theme-secondary-bg-color, #f1f1f1);
  --app-theme-section-bg-color: var(--tg-theme-section-bg-color, #ffffff);
  --app-theme-section-header-text-color: var(--tg-theme-section-header-text-color, #168acd);
  --app-theme-subtitle-text-color: var(--tg-theme-subtitle-text-color, #999999);
  --app-theme-text-color: var(--tg-theme-text-color, #000000);
  --app-viewport-height: var(--tg-viewport-height, 590px);
  --app-viewport-stable-height: var(--tg-viewport-stable-height, 590px);

  /* set colors */
  color-scheme: var(--app-color-scheme);
  background-color: var(--app-theme-bg-color);
  color: var(--app-theme-text-color);

  /* typography */
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.section {
  background-color: var(--app-theme-secondary-bg-color);
  padding: 0.6rem 0.75rem;
  border-radius: 1rem;
  margin: 1rem 0.5rem;
}

.section-title {
  color: var(--app-theme-section-header-text-color);
  margin-bottom: 1rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

import ProductDataLoadingView from './page/ProductDataLoadingView.vue';
import { useSourceDataStore } from './store/SourceDataStore';

import { useWebAppBackButton, useWebApp, useWebAppPopup } from 'vue-tg';
const { close, initDataUnsafe } = useWebApp();
const { onBackButtonClicked, showBackButton } = useWebAppBackButton();
const { showAlert } = useWebAppPopup();
import router from './router';

const sourceDataStore = useSourceDataStore();
const isLoading = ref<boolean>(false);

onMounted(() => {
  showBackButton();
  console.log("\n⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣤⣤⣤⣤⣤⣤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀\n⠀⠀⠀⠀⠀⣠⣴⣿⣿⠿⠛⠛⠛⠛⠛⠛⠿⣿⣿⣦⣄⠀⠀⠀⠀⠀\n⠀⠀⠀⣠⣾⡿⠟⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⢿⣷⣄⠀⠀⠀\n⠀⠀⣼⣿⠟⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⣿⣧⠀⠀\n⠀⣼⣿⠏⠀⢠⣴⡿⠛⠛⢦⡀⠀⠀⢀⣴⡿⠛⠛⢦⡀⠀⠹⣿⣧⠀\n⢰⣿⡏⠀⠀⣾⣿⠀⠀⠀⣀⣷⣤⣤⣾⣿⠀⠀⠀⠀⣷⠀⠀⢹⣿⡆\n⣿⣿⠀⠀⠀⠹⣿⣷⣴⣿⡿⠟⠛⠛⠻⢿⣿⣦⣤⣾⠏⠀⠀⠀⣿⣿\n⣿⣿⠀⠀⠀⠀⢨⣿⡿⠋⢀⣠⣤⣤⣄⡀⠙⢿⣿⡅⠀⠀⠀⠀⣿⣿\n⢿⣿⣤⣾⡷⠀⣾⣿⠁⠰⣿⣿⣿⣿⣿⣿⠆⠈⣿⣷⠀⢾⣷⣤⣿⡿\n⠈⣿⣿⠉⠀⠀⣿⣿⠀⠀⣿⣿⠛⠛⣿⣿⠀⠀⣿⣿⠀⠀⠉⣿⣿⠁\n⠀⣿⣿⠀⠀⠀⢻⣿⡄⢠⣿⣿⣤⣤⣿⣿⡄⢠⣿⡟⠀⠀⠀⣿⣿⠀\n⠀⠙⠋⠀⠀⠀⠈⠻⣿⣿⠿⠛⠛⠛⠛⠿⣿⣿⠟⠁⠀⠀⠀⠙⠋⠀\n\n                  Jake the Dog");
  console.log("Your Telegram Init data: ", initDataUnsafe);
  
  const currentTimestamp: number = Math.floor(Date.now() / 1000);
  const expiration_timeout = process.env.VUE_APP_WEBAPP_INIT_DATA_EXPIRATION_TIMEOUT as number;
  console.log("Environment: ", process.env);
  console.log(`Telegram auth date: ${initDataUnsafe.auth_date}`);
  console.log(`Browser date: ${currentTimestamp}`);
  console.log(`Expiration date: ${expiration_timeout}`);
  if (currentTimestamp > initDataUnsafe.auth_date + expiration_timeout) {
    showAlert(
      "Время авторизации истекло, вам необходимо открыть приложение заново!\n\n"+
      "Алгоритм проверки так же зависит от системного времени вашего устройства. Проверьте, верно ли оно настроено.", 
      () => {
        close();
      }
    );
  }
  
});

onBackButtonClicked(() => {
  if (window.history.state.back === null) {
    close();
    return;
  }
  router.back();
});

onMounted(async () => {
  isLoading.value = true;
  await sourceDataStore.loadData();
  isLoading.value = false;
});

</script>