// import { OrderItemSchema, OrderSchema } from "@/schema/orderDataSchema";
import { OrderItemSchema } from "@/schema/orderDataSchema";
import { AttributeSelection, FeatureSelection } from "@/schema/orderDataSchema";
import { defineStore } from "pinia";
import { v4 as uuidv4 } from 'uuid';

export type OrderItem = {
    productSlug: string;
    quantity: number;
    selectedAttributes: AttributeSelection;
    selectedFeatures: FeatureSelection;
    calculatedPrice: number;
    GUID: string;
};


export const useOrderStore = defineStore("orderStore", {
    state: () => ({
        orderItems: [] as OrderItem[],
    }),
    actions: {
        /**
         * Creates order item
         * @param productSlug product's slug
         * @param quantity order item quantity
         * @param selectedAttributes attributes selection
         * @param selectedFeatures features selection
         * @param calculatedPrice calculated price (variation + features) * quantity
         */
        createOrderItem(
            productSlug: string,
            quantity: number,
            selectedAttributes: AttributeSelection,
            selectedFeatures: FeatureSelection,
            calculatedPrice: number
        ): void {
            this.orderItems.push({
                productSlug: productSlug,
                quantity: quantity,
                selectedAttributes: selectedAttributes,
                selectedFeatures: selectedFeatures,
                calculatedPrice: calculatedPrice,
                GUID: uuidv4(),
            });
        },
        /**
         * Deletes order item by it's GUID
         * @param GUID GUID of order item to delete
         * @returns true if deleted
         */
        deleteOrderItem(GUID: string): boolean {
            const indexToDelete = this.orderItems.findIndex(orderItem => orderItem.GUID === GUID);

            if (indexToDelete !== -1) {
                this.orderItems.splice(indexToDelete, 1);
                return true;
            }
            return false;
        },
        /**
         * Converts order items in store for payload
         * @returns array of OrderItemSchema ready for payload
         */
        convertForPayload(): OrderItemSchema[] {
            const result = [] as OrderItemSchema[];

            this.orderItems.forEach((orderItem) => {
                const selected_features = {} as { [key: string]: string[] };

                for (const selectedFeatureSlug in orderItem.selectedFeatures) {
                    selected_features[selectedFeatureSlug] = Array.from(orderItem.selectedFeatures[selectedFeatureSlug].values());
                }

                result.push({
                    product_slug: orderItem.productSlug,
                    quantity: orderItem.quantity,
                    selected_attributes: orderItem.selectedAttributes,
                    selected_features: selected_features
                });
            });

            return result;
        },
        /**
         * Calculates total price for all order items
         * @returns total price for all order items
         */
        calculateTotalPrice(): number {
          let result = 0.00;

          this.orderItems.forEach((orderItem) => {
            result += +orderItem.calculatedPrice;
          });

          return result;
        }
    }
});